import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card, Col, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { CloseOutlined } from '@ant-design/icons';
import ClientCompany from '../../bookingOrders/types/ClientCompany';
import ClientCompanyAPI from '../../bookingOrders/api/ClientCompanyAPI';
import { useInsights } from '../../context/insights';
import User, { UserRole } from '../../user/types/User';
import { sortInstructorsByFavorite } from '../../user/userUtils';
import UserAPI from '../../user/UserAPI';
import { useUser } from '../../user/userContext';
import Course from '../../course/types/Course';
import CourseAPI from '../../course/CourseAPI';
import InvoiceRecipientAPI from '../../bookingOrders/api/InvoiceRecipientAPI';
import InvoiceRecipient from '../../bookingOrders/types/InvoiceRecipient';
import SubsidiaryCompany from '../../subsidiaryCompany/types/SubsidiaryCompany';
import SubsidiaryCompanyAPI from '../../subsidiaryCompany/SubsidiaryCompanyAPI';
import ClientCompanySelectOption from '../../clientCompany/ClientCompanySelectOption';

type InsightsExtendedFiltersProps = {
  show: boolean;
  close: () => void;
};

const InsightsExtendedFilters: React.FC<InsightsExtendedFiltersProps> = ({
  show,
  close,
}) => {
  const { t } = useTranslation();
  const [currentUser] = useUser();

  const {
    market,
    subsidiaryCompanyIds,
    setSubsidiaryCompanyIds,
    clientCompanyIds,
    setClientCompanyIds,
    instructorIds,
    setInstructorIds,
    courseIds,
    setCourseIds,
    responsibleBookerIds,
    setResponsibleBookerIds,
    invoiceRecipientIds,
    setInvoiceRecipientIds,
  } = useInsights();

  const [subsidiaryCompanies, setSubsidiaryCompanies] =
    useState<SubsidiaryCompany[]>();
  const [loadingSubsidiaryCompanies, setLoadingSubsidiaryCompanies] =
    useState(false);

  const [clientCompanies, setClientCompanies] = useState<ClientCompany[]>();
  const [loadingClientCompanies, setLoadingClientCompanies] = useState(false);

  const [instructors, setInstructors] = useState<User[]>();
  const [loadingInstructors, setLoadingInstructors] = useState(false);

  const [courses, setCourses] = useState<Course[]>();
  const [loadingCourses, setLoadingCourses] = useState(false);

  const [bookers, setBookers] = useState<User[]>();
  const [loadingBookers, setLoadingBookers] = useState(false);

  const [invoiceRecipients, setInvoiceRecipients] =
    useState<InvoiceRecipient[]>();
  const [loadingInvoiceRecipients, setLoadingInvoiceRecipients] =
    useState(false);

  const fetchSubsidiaryCompanies = useCallback(async () => {
    setLoadingSubsidiaryCompanies(true);
    try {
      const { data } = await SubsidiaryCompanyAPI.getSubsidiaryCompanies();
      setSubsidiaryCompanies(data);
    } finally {
      setLoadingSubsidiaryCompanies(false);
    }
  }, []);

  const fetchClientCompanies = useCallback(async () => {
    setLoadingClientCompanies(true);
    try {
      const { data } = await ClientCompanyAPI.getClientCompanies();
      setClientCompanies(data);
    } finally {
      setLoadingClientCompanies(false);
    }
  }, []);

  const fetchInstructors = useCallback(async () => {
    setLoadingInstructors(true);
    try {
      const { data } = await UserAPI.getUsers({
        roles: [UserRole.Instructor],
      });
      const sortedInstructors = sortInstructorsByFavorite(data, currentUser);
      setInstructors(sortedInstructors);
    } finally {
      setLoadingInstructors(false);
    }
  }, [currentUser]);

  const fetchCourses = useCallback(async () => {
    setLoadingCourses(true);
    try {
      const { data } = await CourseAPI.getCourses({ marketId: market?.id });
      setCourses(data);
    } finally {
      setLoadingCourses(false);
    }
  }, [market?.id]);

  const fetchBookers = useCallback(async () => {
    setLoadingBookers(true);
    try {
      const { data } = await UserAPI.getUsers({
        roles: [UserRole.Admin, UserRole.Booker],
      });
      setBookers(data);
    } finally {
      setLoadingBookers(false);
    }
  }, []);

  const fetchInvoiceRecipients = useCallback(async () => {
    setLoadingInvoiceRecipients(true);
    try {
      const { data } = await InvoiceRecipientAPI.getInvoiceRecipients();
      setInvoiceRecipients(data.data);
    } finally {
      setLoadingInvoiceRecipients(false);
    }
  }, []);

  useEffect(() => {
    fetchSubsidiaryCompanies();
    fetchClientCompanies();
    fetchInstructors();
    fetchCourses();
    fetchBookers();
    fetchInvoiceRecipients();
  }, [
    fetchBookers,
    fetchClientCompanies,
    fetchCourses,
    fetchInstructors,
    fetchInvoiceRecipients,
    fetchSubsidiaryCompanies,
  ]);

  const clearFilters = useCallback(() => {
    setSubsidiaryCompanyIds(undefined);
    setClientCompanyIds(undefined);
    setInstructorIds(undefined);
    setCourseIds(undefined);
    setResponsibleBookerIds(undefined);
    setInvoiceRecipientIds(undefined);
  }, [
    setClientCompanyIds,
    setCourseIds,
    setInstructorIds,
    setInvoiceRecipientIds,
    setResponsibleBookerIds,
    setSubsidiaryCompanyIds,
  ]);

  if (!show) {
    return null;
  }

  return (
    <Card className="relative w-full border-1 border-solid border-gray-500">
      <Button
        icon={<CloseOutlined />}
        onClick={close}
        className="absolute top-[16px] right-[16px]"
      />

      <Row className="text-black text-xl font-semibold">
        {t('components.InsightsExtendedFilters.title')}
      </Row>

      <Row gutter={[24, 12]} className="my-4">
        <Filter
          value={subsidiaryCompanyIds}
          setValue={setSubsidiaryCompanyIds}
          label={t(
            'components.InsightsExtendedFilters.subsidiaryCompaniesLabel',
          )}
          placeholder={t(
            'components.InsightsExtendedFilters.subsidiaryCompaniesSelect',
          )}
          loading={loadingSubsidiaryCompanies}
          options={subsidiaryCompanies}
        />

        <Col span={12} className="flex flex-col gap-1">
          <span className="text-md font-semibold">
            {t('components.InsightsExtendedFilters.clientCompaniesLabel')}
          </span>
          <Select
            value={clientCompanyIds}
            placeholder={t(
              'components.InsightsExtendedFilters.clientCompaniesSelect',
            )}
            onChange={setClientCompanyIds}
            loading={loadingClientCompanies}
            popupMatchSelectWidth={false}
            mode="multiple"
            allowClear>
            {clientCompanies?.map((cc) => (
              <Select.Option key={cc.id} value={cc.id}>
                <ClientCompanySelectOption clientCompany={cc} />
              </Select.Option>
            ))}
          </Select>
        </Col>

        <Filter
          value={instructorIds}
          setValue={setInstructorIds}
          label={t('components.InsightsExtendedFilters.instructorsLabel')}
          placeholder={t(
            'components.InsightsExtendedFilters.instructorsSelect',
          )}
          loading={loadingInstructors}
          options={instructors}
        />

        <Filter
          value={courseIds}
          setValue={setCourseIds}
          label={t('components.InsightsExtendedFilters.coursesLabel')}
          placeholder={t('components.InsightsExtendedFilters.coursesSelect')}
          loading={loadingCourses}
          options={courses}
        />

        <Filter
          value={responsibleBookerIds}
          setValue={setResponsibleBookerIds}
          label={t(
            'components.InsightsExtendedFilters.responsibleBookersLabel',
          )}
          placeholder={t(
            'components.InsightsExtendedFilters.responsibleBookersSelect',
          )}
          loading={loadingBookers}
          options={bookers}
        />

        <Filter
          value={invoiceRecipientIds}
          setValue={setInvoiceRecipientIds}
          label={t('components.InsightsExtendedFilters.invoiceRecipientsLabel')}
          placeholder={t(
            'components.InsightsExtendedFilters.invoiceRecipientsSelect',
          )}
          loading={loadingInvoiceRecipients}
          options={invoiceRecipients}
        />
      </Row>

      <Row>
        <Button
          type="default"
          onClick={clearFilters}
          disabled={
            (subsidiaryCompanyIds === undefined ||
              subsidiaryCompanyIds.length === 0) &&
            (clientCompanyIds === undefined || clientCompanyIds.length === 0) &&
            (instructorIds === undefined || instructorIds.length === 0) &&
            (courseIds === undefined || courseIds.length === 0) &&
            (responsibleBookerIds === undefined ||
              responsibleBookerIds.length === 0) &&
            (invoiceRecipientIds === undefined ||
              invoiceRecipientIds.length === 0)
          }>
          {t('components.InsightsExtendedFilters.clearButton')}
        </Button>
      </Row>
    </Card>
  );
};

export default InsightsExtendedFilters;

type FilterProps = {
  value: number[] | undefined;
  setValue: (v: number[]) => void;
  label: string;
  placeholder: string;
  loading: boolean;
  options?: { id: number | null; name: string }[];
};

const Filter: React.FC<FilterProps> = ({
  value,
  setValue,
  label,
  placeholder,
  loading,
  options,
}) => {
  return (
    <Col span={12} className="flex flex-col gap-1">
      <span className="text-md font-semibold">{label}</span>

      <Select
        value={value}
        placeholder={placeholder}
        onChange={(e) => setValue(e as number[])}
        loading={loading}
        popupMatchSelectWidth={false}
        mode="multiple"
        allowClear>
        {options?.map((o) => (
          <Select.Option key={o.id} value={o.id}>
            {o.name}
          </Select.Option>
        ))}
      </Select>
    </Col>
  );
};
