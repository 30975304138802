import React from 'react';
import ClientCompany from '../bookingOrders/types/ClientCompany';

type ClientCompanySelectOptionProps = {
  clientCompany: ClientCompany;
};

const ClientCompanySelectOption: React.FC<ClientCompanySelectOptionProps> = ({
  clientCompany,
}) => {
  return (
    <div className="flex flex-col">
      {clientCompany.name}
      <div className="text-xs text-gray-600 w-0">
        Placeholder Subsidiary Company
      </div>
    </div>
  );
};

export default ClientCompanySelectOption;
