import { CloseOutlined } from '@ant-design/icons';
import { Button, Form, Input, Select } from 'antd';
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import ClientCompanyAPI from '../../bookingOrders/api/ClientCompanyAPI';
import ClientCompany from '../../bookingOrders/types/ClientCompany';
import ContentCard from '../../components/ContentCard';
import {
  getSearchParamsAnyOf,
  stringifyValues,
} from '../../utils/searchParams';
import { InvoiceRecipientViewApiFiler } from '../../bookingOrders/api/InvoiceRecipientAPI';

export type InvoiceRecipientFilterFormValues = {
  invoiceRecipientName?: string;
  clientCompanies?: number[];
  organizationNumber?: string;
  vismaId?: string;
  email?: string;
  sortBy?: InvoiceRecipientViewApiFiler['sortBy'];
  sortOrder?: InvoiceRecipientViewApiFiler['sortOrder'];
};

type InvoiceRecipientFilterProps = {
  visible: boolean;
};

const InvoiceRecipientFilter = ({ visible }: InvoiceRecipientFilterProps) => {
  const [form] = Form.useForm<InvoiceRecipientFilterFormValues>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [clientCompanies, setClientCompanies] = useState<ClientCompany[]>();
  const [loadingClientCompanies, setLoadingClientCompanies] = useState(false);

  const { t } = useTranslation();

  const fetchClientCompanies = useCallback(async () => {
    setLoadingClientCompanies(true);
    try {
      const { data } = await ClientCompanyAPI.getClientCompanies();
      setClientCompanies(data);
    } finally {
      setLoadingClientCompanies(false);
    }
  }, []);

  useEffect(() => {
    if (visible) {
      fetchClientCompanies();
    }
  }, [fetchClientCompanies, visible]);

  useEffect(() => {
    const formValues: InvoiceRecipientFilterFormValues = {
      invoiceRecipientName: searchParams.get('invoiceRecipientName') ?? '',
      clientCompanies: searchParams.getAll('clientCompanies').map(Number) ?? [],
      organizationNumber: searchParams.get('organizationNumber') ?? '',
      vismaId: searchParams.get('vismaId') ?? '',
      email: searchParams.get('email') ?? '',
      sortBy: getSearchParamsAnyOf(searchParams, 'sortBy', [
        'TITLE',
        'BOOKING_ORDERS_COUNT',
      ]),
      sortOrder: getSearchParamsAnyOf(searchParams, 'sortOrder', [
        'ASC',
        'DESC',
      ]),
    };
    form.setFieldsValue(formValues);
  }, [form, searchParams]);

  const isEmpty = useCallback(
    () => Object.values(form.getFieldsValue()).filter(Boolean).length <= 0,
    [form],
  );

  const clearFilter = useCallback(() => {
    setSearchParams({});
  }, [setSearchParams]);

  type SelectOption = { key: string; content: ReactNode };

  const sortByOptions: SelectOption[] = useMemo(
    () => [
      {
        key: 'TITLE',
        content: t('common.title'),
      },
      {
        key: 'BOOKING_ORDERS_COUNT',
        content: t('components.InvoiceRecipientFilter.amountOfBookingOrders'),
      },
    ],
    [t],
  );

  const sortOrderOptions: SelectOption[] = useMemo(
    () => [
      { key: 'ASC', content: t('components.InvoiceRecipientFilter.ascending') },
      {
        key: 'DESC',
        content: t('components.InvoiceRecipientFilter.descending'),
      },
    ],
    [t],
  );

  return (
    <ContentCard className={`flex flex-col ${!visible ? 'hidden' : ''}`}>
      <Button
        type="text"
        icon={<CloseOutlined />}
        disabled={isEmpty()}
        className="self-end mb-2"
        onClick={clearFilter}>
        {t('common.clear')}
      </Button>
      <Form
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        onValuesChange={(_, values) => {
          setSearchParams(
            stringifyValues(
              values,
              (value) => value === undefined || value === '',
            ),
          );
        }}>
        <div className="md:grid col-gap-4 grid-cols-2">
          <Form.Item label={t('common.title')} name="invoiceRecipientName">
            <Input />
          </Form.Item>

          <Form.Item
            label={t('components.InvoiceRecipientFilter.usedByCompanies')}
            name="clientCompanies">
            <Select
              mode="multiple"
              allowClear
              loading={loadingClientCompanies}
              filterOption={(input, option) =>
                option?.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }>
              {clientCompanies?.map((clientCompany) => (
                <Select.Option key={clientCompany.id} value={clientCompany.id}>
                  {clientCompany.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label={t('common.corporateId')} name="organizationNumber">
            <Input />
          </Form.Item>

          <Form.Item
            label={t('components.InvoiceRecipientFilter.vismaId')}
            name="vismaId">
            <Input />
          </Form.Item>

          <Form.Item label={t('common.email')} name="email">
            <Input />
          </Form.Item>
        </div>
        <div className="md:grid col-gap-4 grid-cols-2 pt-4">
          <Form.Item
            label={t('components.InvoiceRecipientFilter.sortBy')}
            name="sortBy">
            <Select allowClear>
              {sortByOptions.map(({ key, content }) => (
                <Select.Option key={key} value={key}>
                  {content}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label={t('components.InvoiceRecipientFilter.sortOrder')}
            name="sortOrder">
            <Select allowClear>
              {sortOrderOptions.map(({ key, content }) => (
                <Select.Option key={key} value={key}>
                  {content}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      </Form>
    </ContentCard>
  );
};

export default InvoiceRecipientFilter;
