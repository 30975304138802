import React, { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useInsights } from '../../context/insights';
import { IntervalOption } from '../types/InsightsFilters';
import { getMarkets } from '../../market/MarketAPI';
import Market from '../../market/types/Market';
import { FilterOutlined } from '@ant-design/icons';
import { useUser } from '../../user/userContext';
import { CourseInstanceStatusYMode } from '../types/CourseInstanceStatus';

type InsightsFiltersProps = {
  toggleShowExtendedFilters: () => void;
  showExtendedFilters: boolean;
};

const InsightsFilters: React.FC<InsightsFiltersProps> = ({
  toggleShowExtendedFilters,
  showExtendedFilters,
}) => {
  const { t } = useTranslation();
  const {
    market,
    setMarket,
    subsidiaryCompanyIds,
    setSubsidiaryCompanyIds,
    clientCompanyIds,
    instructorIds,
    courseIds,
    responsibleBookerIds,
    invoiceRecipientIds,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    setStatusYMode,
  } = useInsights();
  const [currentUser] = useUser();

  const [interval, setInterval] = useState<IntervalOption | undefined>(
    IntervalOption.Monthly,
  );
  const [markets, setMarkets] = useState<Market[]>();
  const [loadingMarkets, setLoadingMarkets] = useState(false);

  const fetchMarkets = useCallback(async () => {
    setLoadingMarkets(true);
    try {
      const { data } = await getMarkets();
      setMarkets(data);

      const currentUserMarketId = currentUser?.market.id;
      const currentUserMarket = data.find((m) => m.id === currentUserMarketId);

      if (currentUserMarket) {
        setMarket(currentUserMarket);
      }

      if (currentUser?.preferredSubsidiaryCompany?.id) {
        setSubsidiaryCompanyIds([currentUser.preferredSubsidiaryCompany.id]);
        setStatusYMode(CourseInstanceStatusYMode.REVENUE);
      }
    } finally {
      setLoadingMarkets(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchMarkets();
  }, [fetchMarkets]);

  const intervalOptions = Object.values(IntervalOption).map((io) => ({
    value: io,
    label: t(`components.InsightsFilters.IntervalOptions.${io}`),
  }));

  const onIntervalChange = useCallback(
    ({ target: { value } }: RadioChangeEvent) => {
      setInterval(value);
      setEndDate(dayjs());

      switch (value) {
        case IntervalOption.Yearly: {
          setStartDate(dayjs().subtract(12, 'month'));
          break;
        }
        case IntervalOption.Quaterly: {
          setStartDate(dayjs().subtract(3, 'month'));
          break;
        }
        case IntervalOption.Monthly: {
          setStartDate(dayjs().subtract(1, 'month'));
          break;
        }
        case IntervalOption.Weekly: {
          setStartDate(dayjs().subtract(7, 'day'));
          break;
        }
        default:
          return;
      }
    },
    [setEndDate, setStartDate],
  );

  const numberOfFiltersSelected = [
    subsidiaryCompanyIds,
    clientCompanyIds,
    instructorIds,
    courseIds,
    responsibleBookerIds,
    invoiceRecipientIds,
  ].filter((el) => (el as unknown as number[])?.length > 0).length;

  const highlightFilterButton =
    numberOfFiltersSelected > 0 || showExtendedFilters;

  return (
    <Row gutter={32} className="items-center">
      <Col>
        <Row className="mb-1 text-grayDark font-semibold">
          {t('components.InsightsFilters.interval')}
        </Row>

        <Row gutter={16} className="items-center">
          <Col>
            <Radio.Group
              options={intervalOptions}
              onChange={onIntervalChange}
              value={interval}
              optionType="button"
            />
          </Col>

          <Col>
            <DatePicker.RangePicker
              value={[startDate, endDate]}
              onChange={(values) => {
                setInterval(undefined);
                if (values) {
                  setStartDate(values[0] as dayjs.Dayjs);
                  setEndDate(values[1] as dayjs.Dayjs);
                } else {
                  setStartDate(dayjs().subtract(3, 'month'));
                  setEndDate(dayjs());
                }
              }}
            />
          </Col>
        </Row>
      </Col>

      <Col className="h-[50%] self-end">
        <Divider type="vertical" className="h-full" />
      </Col>

      <Col>
        <Row className="mb-1 text-grayDark font-semibold">
          {t('common.market')}
        </Row>

        <Row gutter={16} className="items-center">
          <Col>
            <Select
              value={market?.id ?? t('components.InsightsFilters.allMarkets')}
              onChange={(e) => setMarket(markets?.find((m) => m.id === e))}
              loading={loadingMarkets}
              popupMatchSelectWidth={false}>
              <Select.Option key={0} value={undefined}>
                {t('components.InsightsFilters.allMarkets')}
              </Select.Option>

              {markets?.map((m) => (
                <Select.Option key={m.id} value={m.id}>
                  {m.name}
                </Select.Option>
              ))}
            </Select>
          </Col>

          <Col>
            <Button
              type={highlightFilterButton ? 'primary' : 'default'}
              ghost={highlightFilterButton}
              icon={<FilterOutlined />}
              onClick={toggleShowExtendedFilters}>
              {t('components.InsightsFilters.filter', {
                count: numberOfFiltersSelected,
              })}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default InsightsFilters;
