import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Empty, Spin } from 'antd';
import { debounce } from 'lodash';
import InfiniteScroll from 'react-infinite-scroller';
import { useTranslation } from 'react-i18next';

import BookingOrderAPI from '../api/BookingOrderAPI';
import BookingOrder from '../types/BookingOrder';
import { useUser } from '../../user/userContext';
import BookingOrderCard from '../components/BookingOrderCard';
import PaginationParams from '../../types/PaginationParams';
import PaginationMeta from '../../types/PaginationMeta';
import BookingOrderFilterPicker, {
  FilterPickerValue,
} from '../components/BookingOrderFilterPicker';
import ApiResponse from '../../types/ApiResponse';
import { Permission } from '../../user/types/Permission';
import { userHasPermission } from '../../user/userUtils';

const BookingOrders: React.FC = () => {
  const [currentUser] = useUser();
  const [bookingOrders, setBookingOrders] = useState<BookingOrder[]>([]);
  const [filterPickerValue, setFilterPickerValue] =
    useState<FilterPickerValue>();
  const [loadingBookingOrders, setLoadingBookingOrders] =
    useState<boolean>(true);
  const [paginationParams, setPaginationParams] =
    useState<PaginationParams | null>({
      page: 1,
    });
  const [paginationMeta, setPaginationMeta] = useState<PaginationMeta | null>();
  const [currentFilterCategoryName, setCurrentFilterCategoryName] =
    useState<string>();
  const [filterSubsidiaryCompanyIds, setFilterSubsidiaryCompanyIds] =
    useState<number[]>();

  const { t } = useTranslation();

  const fetchBookingOrders = useCallback(
    async (
      _filterPickerValue?: FilterPickerValue,
      _paginationParams?: PaginationParams | null,
    ) => {
      setLoadingBookingOrders(true);
      try {
        let response: ApiResponse<BookingOrder[], PaginationMeta>;

        if (_filterPickerValue?.incompleteBookingOrderFilter) {
          const { data } = await BookingOrderAPI.getIncompleteBookingOrders(
            _filterPickerValue?.incompleteBookingOrderFilter,
            _paginationParams,
          );
          response = data;
        } else {
          const { data } = await BookingOrderAPI.getBookingOrders(
            _filterPickerValue?.bookingOrderFilter,
            _paginationParams,
          );
          response = data;
        }

        setBookingOrders(
          _paginationParams == null
            ? response.data
            : (prevState) => {
                return [...prevState].concat(response.data);
              },
        );

        setPaginationMeta(response.meta);
        setPaginationParams({ page: response.meta.page + 1 });
      } finally {
        setLoadingBookingOrders(false);
      }
    },
    [],
  );

  useEffect(() => {
    if (
      !currentUser ||
      !userHasPermission(currentUser, Permission.BOOKING_ORDER_READ)
    ) {
      return;
    }

    const subsidiaryCompanyIds =
      userHasPermission(currentUser, Permission.BOOKING_ORDER_READ_GLOBAL) &&
      currentUser.preferredSubsidiaryCompany
        ? [currentUser.preferredSubsidiaryCompany.id]
        : currentUser.subsidiaryCompanies.length > 0
          ? currentUser.subsidiaryCompanies
              .filter((company) => company.market.id == currentUser.market.id)
              .map((subsidiaryCompany) => subsidiaryCompany.id)
          : undefined;

    setFilterSubsidiaryCompanyIds(subsidiaryCompanyIds);
  }, [currentUser]);

  useEffect(() => {
    if (filterPickerValue === undefined || !filterSubsidiaryCompanyIds) {
      return;
    }

    setBookingOrders([]);
    fetchBookingOrders(filterPickerValue);
    setPaginationParams({ page: 1 });
    setPaginationMeta(null);
  }, [fetchBookingOrders, filterPickerValue, filterSubsidiaryCompanyIds]);

  /*
   * Debounce the call to fetchBookingsOrders to prevent the infinite scroll component
   * from calling it multiple times before the state has finished updating.
   */
  const debounceFetchBookingOrders = useMemo(
    () =>
      debounce(
        (
          _filterPickerValue?: FilterPickerValue,
          _paginationParams?: PaginationParams | null,
        ) => fetchBookingOrders(_filterPickerValue, _paginationParams),
        10,
      ),
    [fetchBookingOrders],
  );

  return (
    <>
      <div className="flex flex-row items-end mt-2 mb-6">
        <h2>{t('views.BookingOrders.orders')}</h2>
        {currentFilterCategoryName && (
          <>
            <h2 className="text-lg text-blueGrayDark mx-1">・</h2>
            <h2 className="font-light text-lg text-blueGrayDark">
              {currentFilterCategoryName}
            </h2>
          </>
        )}
      </div>
      <div className="flex flex-row">
        <div className="w-3/4">
          <>
            <InfiniteScroll
              loadMore={() => {
                if (!loadingBookingOrders) {
                  setLoadingBookingOrders(true);
                  return debounceFetchBookingOrders(filterPickerValue, {
                    ...paginationParams,
                    page: paginationParams?.page,
                  });
                }
              }}
              hasMore={
                !loadingBookingOrders &&
                (paginationMeta?.page ?? 1) < (paginationMeta?.lastPage ?? 1)
              }>
              {bookingOrders?.map((bookingOrder) => (
                <BookingOrderCard
                  key={bookingOrder.id}
                  bookingOrder={bookingOrder}
                />
              ))}
            </InfiniteScroll>
          </>
          {bookingOrders.length === 0 && !loadingBookingOrders && (
            <Empty
              className="pt-3"
              image={Empty.PRESENTED_IMAGE_DEFAULT}
              description={<span> {t('views.BookingOrders.noOrders')}</span>}
            />
          )}
          {loadingBookingOrders && (
            <div className="flex justify-center pt-10">
              <Spin size="large" />
            </div>
          )}
        </div>
        {filterSubsidiaryCompanyIds !== undefined && (
          <BookingOrderFilterPicker
            setCurrentFilterCategoryName={setCurrentFilterCategoryName}
            setFilter={setFilterPickerValue}
            className="w-1/4 pl-4"
            filterSubsidiaryCompanyIds={filterSubsidiaryCompanyIds}
          />
        )}
      </div>
    </>
  );
};

export default BookingOrders;
